@tailwind base;
@tailwind components;
@tailwind utilities;

@font-face {
    font-family: Agustina;
    src: url("./assets/fonts/Agustina-Signature.otf") format("truetype");
}

html,
body {
    scroll-behavior: smooth;
}

.signature {
    font-family: Agustina;
}

.active {
    color: white;
    border-bottom: 1px solid white;
}

.deactive {
    color: #7e9199;
    border-bottom: none;
}

/* Custom Scrollbar */

::-webkit-scrollbar {
    width: 10px;
}

::-webkit-scrollbar-track {
    background: #f1f1f1;
}

::-webkit-scrollbar-thumb {
    background: #888;
}

::-webkit-scrollbar-thumb:hover {
    background: #555;
}

.navbar {
    position: sticky;
    top: 0;
    z-index: 50; /* Ensures the navbar stays on top of other content */
    background-color: #333; /* Dark background color */
    padding: 20px 15px; /* Adds padding to the top-bottom and left-right */
    border-radius: 10px; /* Adds rounded corners */
  }
  

.styled-link {
    color: tomato; /* Set link color to blue */
}
.styled-link:hover {
    text-decoration: underline; /* Underline on hover */
  }